import {
  SET_QUESTION_OBJECT,
  SET_SUB_SKILLS,
  SET_STEPPER_OBJECT,
} from "../action/questionsObject";

const initialState = {
  questionObject: {},
  subSkills: [],
};
export default function QuestionJsonReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUB_SKILLS:
      return {
        ...state,
        subSkills: action.payload,
      };
    case SET_QUESTION_OBJECT:
      return {
        ...state,
        questionObject: action.payload,
      };
    case SET_STEPPER_OBJECT:
      return {
        ...state,
        stepperObject: action.payload,
      };

    // Add more cases as needed
    default:
      return state;
  }
}
