/** @format */

import React, { useEffect, useState, Suspense } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "../Header";
import Stepper from "@mui/material/Stepper";
import Footer from "../Footer";
import { useForm, FormProvider } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import "./style.css";
// import "./StepperForm.css";
import SingleStepForm from "./SingleStepFormV.0.2";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  getResult,
  getStepperQuestionForm,
  saveUserFormData,
  getReferrals,
  getResults,
} from "./stepper.helper";
import { setReferralList } from "../../redux/action/setReferralsList";
import { useDispatch, useSelector } from "react-redux";
import { setUserResult } from "../../redux/action/result";
import { useNavigate } from "react-router-dom";
import StepReferals from "./StepReferals";
import { setLoading } from "../../redux/action/loadingRequest";
import stepAlert from "./stepAlert.json";
import toast from "react-hot-toast";
import _ from "lodash";
import Loading from "../UI/Loading";
import { getEmail } from "../../utils/storage";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { useMediaQuery } from "@mui/material";
import { Alert, Snackbar, LinearProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PizzaPopHover from "../common/PizzaPopHover";
import PaymentForm from "./PaymentService";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import DeviceInfoAndLocation from "../common/DeviceInfoAndLocation";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import TrueTips from "../common/TrueTips";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import { motion, AnimatePresence } from "framer-motion";
import { setIncognitoMode } from "../../redux/action/IncognitoMode";
import {
  setSubSkills,
  setQuestionJson,
  setStepperObject,
} from "../../redux/action/questionsObject";
import { keyframes } from "@emotion/react";
import LoadingAnimation from "../common/LoadingAnimation";

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const RecalculatingText = (text) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontSize: "24px",
        animation: `${blinkAnimation} 1.5s linear infinite`,
      }}
    >
      Re-calculating...
    </Typography>
  );
};

function StepperForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setStepperJsonObject] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referrals, setReferrals] = React.useState([]);
  const [oldFormObject, setOldFormObject] = React.useState([]);
  const [questionJsonObject, setQuestionJsonObject] = React.useState([]);
  const [stepperLoader, setStepperLoader] = React.useState(true);
  const flagValue = useFeatureFlag("Pre_load_user_form");
  const [open, setOpen] = React.useState(true);
  const [userFormData, setUserFormData] = React.useState();
  const [referralslist, setReferralsList] = useState();
  const [userName, setUserName] = React.useState(getEmail());
  const referralFlag = useFeatureFlag("Referrals_mandatory");
  const userEmail = getEmail();
  const basePrice = useFeatureFlag("Base_price");
  const offValue = useFeatureFlag("discount_value");
  const numberOfEvaluationResult = useFeatureFlag("numberOfEvaluationResult");
  const UserFromTechValue = useFeatureFlag("UserFromTechValue");
  const discountedPrice = basePrice - offValue;
  const [results, setResults] = useState(0);
  const [formData, setFormData] = useState();
  const [referralDiscountPrice, setReferralDiscountPrice] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTrueTipRemain = useMediaQuery("(max-width:1200px)");
  const resultData = useSelector(
    (state) => state?.ResultReducer?.previousResult || undefined
  );
  const relevanceResult = useSelector(
    (state) => state?.ResultReducer?.skill_relevance || ""
  );
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const [reCalculate, setReCalculate] = useState(false);

  // useEffect(() => {
  //   const IncognitoMode = localStorage.getItem("IncognitoUser");
  //   if (IncognitoMode === "true") {
  //     dispatch(setIncognitoMode(true));
  //   } else {
  //     dispatch(setIncognitoMode(false));
  //   }
  // }, []);

  const theme = useTheme();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      suggestedObject: {},
    },
    criteriaMode: "all",
  });

  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  // True Tips state and methods

  const SlideTransition = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const [state, setState] = useState({
    open: false,
    Transition: Fade,
  });

  const handleClick = (Transition) => () => {
    setState({
      open: true,
      Transition,
    });
  };

  const handleCloseTrueTips = () => {
    if (isTrueTipRemain) {
      setState({
        ...state,
        open: false,
      });
    } else {
      setState({
        ...state,
        open: true,
      });
    }
  };
  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    setState({
      open: true,
      Transition: SlideTransition,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  var filterOldFormObject = {};
  questionJsonObject.map(function (item) {
    if (oldFormObject.hasOwnProperty(item.questionName)) {
      filterOldFormObject[item.questionName] = oldFormObject[item.questionName];
    }
  });

  useEffect(() => {
    const temp = [];
    referrals?.map((referral) => {
      if (referral.email?.length > 0) {
        temp.push(referral);
      }
    });
    if (temp.length !== 0) {
      let tempformdata = formData;
      tempformdata.referrals = temp;
      // console.log("tempformdata", tempformdata);
      setFormData(tempformdata);
    }
  }, [formData, referrals]);

  useEffect(() => {
    getStepperQuestionForm(
      methods,
      setStepperJsonObject,
      setStepperObject,
      setQuestionJsonObject,
      setQuestionJson,
      setStepperLoader,
      navigate,
      toast,
      _.isEmpty,
      flagValue,
      setUserFormData,
      dispatch,
      IncognitoModeStatus,
      setSubSkills
    );
    if (!IncognitoModeStatus) {
      getReferrals(setReferralsList, dispatch, setReferralList);
      getResults(setResults);
    }
    setState({
      open: true,
      Transition: SlideTransition,
    });
    // dispatch(setUserResult(""));
    if (IncognitoModeStatus) {
      localStorage.removeItem("UserFrom Deleted");
    }
  }, []);

  useEffect(() => {
    const name = getEmail();
    setUserName(name);
  }, [userEmail]);

  /* Dummy Use Effect to active skill and certificate lambda */

  //Google Analytics events and pageview
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactGA4.send({
      hitType: "pageview",
      page: "/skills-calculation",
      title: "visited StepperForm",
    });
  }, []);

  const handleSteps = (step) => {
    // toast.success(stepAlert[step].alertMessage);
    ReactGA.event({
      category: "stepperForm",
      action: "visited",
      label: steps[step].stepLabel,
    });
    ReactGA4.event({
      category: "stepperForm",
      action: "visited",
      label: steps[step].stepLabel, // optional
    });
    return step === 7 ? (
      <>
        {reCalculate ? (
          <LoadingAnimation
            animation={steps[activeStep]["loadingAnimation"]}
            messages={steps[activeStep]["loadingMessages"]}
            activeStep={activeStep}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0.7, y: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, y: -200 }}
            transition={{ duration: 1 }}
          >
            <StepReferals
              setReferrals={setReferrals}
              questionsObject={questionJsonObject}
              step={steps[step]}
              discountedPrice={discountedPrice}
              referralslist={referralslist}
              setReferralDiscountPrice={setReferralDiscountPrice}
              setInvoiceDetails={setInvoiceDetails}
              results={results}
            />
          </motion.div>
        )}

        {/* <PaymentForm /> */}
      </>
    ) : (
      <>
        {reCalculate ? (
          <LoadingAnimation
            animation={steps[activeStep]["loadingAnimation"]}
            messages={steps[activeStep]["loadingMessages"]}
            activeStep={activeStep}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0.7, y: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, y: -200 }}
            transition={{ duration: 1 }}
          >
            <SingleStepForm
              questionsObject={questionJsonObject}
              activeStepObject={steps[step]}
            />
          </motion.div>
        )}

        {/* {toast.success(stepAlert[step + 1]?.alertMessage)} */}
      </>
    );
  };
  const [finalResult, setFinalResult] = useState({
    marketSalary: "",
    techValue: "",
    techworth: "",
  });
  // const [extraDeviceInfo, setExtraDeviceInfo] = useState({
  //   userlocation: "",
  //   deviceinfo: "",
  // });

  const [location, setLocation] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const [techValue, setTechValue] = useState("");
  const [techWorth, setTechWorth] = useState("");
  const [formLoading, setFormLoading] = useState(true);

  useEffect((item) => {
    DeviceInfoAndLocation(setLocation, setDeviceInfo);
  }, []);

  const handleNext = async (data) => {
    // console.log(referralDiscountPrice, "referralDiscountPrice");
    setState({
      open: true,
      Transition: SlideTransition,
    });
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    /* remove "",null,undefind value from data object */
    data = _.pickBy(data, (v) => v !== null && v !== undefined && v !== "");
    data.suggestedObject = _.pickBy(
      data.suggestedObject,
      (v) => v !== null && v !== undefined && v !== ""
    );
    const temp = [];
    referrals?.map((referral) => {
      if (referral.email?.length > 0) {
        temp.push(referral);
      }
    });
    if (temp.length !== 0) {
      data.referrals = temp;
    }
    if (activeStep >= 0) {
      setUserName(data.name);
    }
    data.deviceinfo = deviceInfo;
    data.userlocation = location;
    data.resultTime = new Date(Date.now()).toUTCString();
    setFormData(data);
    data.lastStepper = steps[activeStep].stepLabel;
    if (activeStep < 7) {
      setReCalculate(true);
      saveUserFormData(
        data,
        dispatch,
        setTechValue,
        setTechWorth,
        setReCalculate
      );
    }
    // referralFlag - referralslist && referral.length < referralLength
    //1-25=-24>0  && 0 < 3 = true error show
    //3-1=2>0  && 1 < 2 = true error show
    //3-2=1>0  && 1 < 1 = false submit
    //3-3=0>0  && 1 < 0 = false submit
    //3-4=-1>0 && 1 < 3 = false submit
    //3-5=-2>0 && 1 < 3 = false submit

    //23-23=0 > 0 && 1 < 3 = false submit
    //24-23=1 > 0 && 1 < 1 = false error show
    // const referralLength = referralFlag - referralslist?.referrals?.length;
    //activeStep === 7 && referralLength > 0 && referrals?.length <= referralLength
    if (referrals?.length < referralFlag && activeStep === 7) {
      // setOpenAlert(true);
      toast.error(`Refer at least ${referralFlag} colleague to proceed`);
    } else {
      if (
        referrals[0]?.email.length === 0 &&
        referralFlag == 1 &&
        activeStep === 7 &&
        referralslist?.referrals?.length < numberOfEvaluationResult
      ) {
        toast.error(`Refer at least ${referralFlag} colleague to proceed`);
      } else {
        var refEmail = referrals?.map(function (item) {
          return item.email;
        });
        var dup = refEmail?.some(function (item, index) {
          return refEmail?.indexOf(item) != index;
        });
        if (dup && activeStep === 7) {
          // setOpenAlert(true);
          // setAlertMessage(" Ypu have entered duplicate emails");
          toast.error("You have entered duplicate emails");
        } else {
          if (activeStep === steps.length - 1) {
            dispatch(setLoading(true));
            data.relevanceResult = relevanceResult;
            getResult(
              data,
              setFinalResult,
              dispatch,
              setUserResult,
              setLoading,
              navigate
            );
            delete data.formFinish;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // toast.success(stepAlert[activeStep]?.alertMessage);
        }
      }
    }
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <>
        {stepperLoader ? (
          <Loading />
        ) : (
          <>
            {userFormData && flagValue && IncognitoModeStatus === false && (
              <Snackbar
                open={open}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                autoHideDuration={7000}
                onClose={handleClose}
                action={action}
              >
                <Alert
                  onClose={handleClose}
                  variant="filled"
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="subtitle1">
                    Data from your previous session is displayed for your
                    convenience. You may change the desired values to
                    re-calculate your results
                  </Typography>
                </Alert>
              </Snackbar>
            )}
            <Header />
            <Container
              maxWidth="x2"
              component="main"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              <Grid
                className="stepper-main-grid"
                container
                justifyContent="center"
                sx={{
                  // height: "100%",
                  // overflow: "scroll",
                  minHeight: {
                    xs: `calc(88vh)`,
                    sm: `calc(80vh)`,
                    md: `calc(88vh)`,
                    lg: `calc(100vh - 96px)`,
                    xl: `calc(100vh - 96px)`,
                  },
                  // height:'100%'
                  mt: { xs: 10, sm: 7, md: 6, lg: "96px", xl: "96px" },
                  // mt: { xs: 10, sm: "128px" },
                }}
                display={"flex"}
                flexDirection="row"
                // className="stepper-form-main"
              >
                <Grid
                  item
                  xs={0}
                  lg={5}
                  xl={6}
                  md={0}
                  sm={0}
                  sx={{
                    backgroundColor: "#4A3D9E",
                    display: { xs: "none", md: "none", lg: "flex" },
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    pt: 8,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    {/* Evaluation for user box */}
                    <Box
                      sx={{
                        padding: "4px",
                        borderRadius: "15px",
                        color: "#fff",
                        textAlign: "center",
                        maxWidth: "410px",
                        width: "100%",
                        m: "0px auto",
                        mb: 6,

                        // display: "flex",
                        // justifyContent: "center",
                        // position: "fixed",
                        // top: "calc(100vh - 80vh)",
                        // left: "calc(100vw - 85vw)",
                      }}
                    >
                      <Typography
                        sx={{
                          backgroundColor: "#6254B8",
                          padding: "4px",
                          borderRadius: "15px",
                          px: 3,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                        display="block"
                      >
                        Evaluating for&nbsp;
                        <Typography
                          noWrap
                          sx={{
                            borderRadiusTopRight: 50,
                            fontWeight: "bold",
                          }}
                        >
                          {userName}
                        </Typography>
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        // background: "#6254B8",
                        // height: "100%",
                        // minHeight: "calc(100vh - 430px)",
                        // width: "calc(100vw - 76vw)",
                        borderRadius: "15px",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        // maxWidth: "541px",
                      }}
                    >
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={activeStep}
                          initial={{ y: 10, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: -10, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              // position: "absolute",
                              // bottom: 0,
                              // top: "0vh",
                              // height: "auto",/
                              maxWidth: { lg: "70%", xl: "100%" },
                            }}
                          >
                            <img
                              // style={{
                              //   maxHeight: "calc(100vh - 330px)",
                              // }}
                              style={{
                                width: "100%",
                                height: "auto",
                                // maxHeight: "calc(100vh - 330px)",
                              }}
                              loading="lazy"
                              src={`../../../assets/images/steps-img/${
                                activeStep === 7 && IncognitoModeStatus
                                  ? "payments"
                                  : activeStep
                              }.svg`}
                            />
                          </Box>
                        </motion.div>
                      </AnimatePresence>
                      {/* <Box
                        sx={{
                          position: "absolute",
                          bottom: 0,
                        }}
                      >
                        <img src={`../../../assets/images/steps/first-icon/${activeStep}.svg`} />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "calc(100vw - 82vw)",
                          left: "calc(100vw - 78.5vw)",
                        }}
                      >
                        <img src={`../../../assets/images/steps/second-icon/${activeStep}.svg`} />
                      </Box> */}
                    </Box>
                    <TrueTips
                      message={steps[activeStep]["truetips"]}
                      vertical={"bottom"}
                      horizontal={"left"}
                      state={state}
                      setState={setState}
                      handleClose={handleCloseTrueTips}
                      autoHideDuration={600000000}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={7}
                  xl={6}
                  md={12}
                  sx={{
                    backgroundColor: IncognitoModeStatus
                      ? theme.palette.primary.incognitoBackground
                      : "#fff",
                    color: IncognitoModeStatus
                      ? theme.palette.primary.incognitoText
                      : "inherit",
                  }}
                  // sx={{ height: "100%" }}
                >
                  {/* mobile stepper steps */}
                  <Box
                    sx={{
                      display: { xs: "flex", sm: "none", md: "none" },
                      // flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                      mx: 1.5,
                      pt: "20px",
                    }}
                  >
                    {steps
                      ?.slice(
                        Math.max(activeStep - 1, 0),
                        Math.min(activeStep + 2, steps.length)
                      )
                      .map((stepObj, index) => {
                        const stepIndex = Math.max(activeStep - 1, 0) + index;
                        const isActive = index === 1;

                        return (
                          <Box
                            key={stepIndex}
                            sx={{
                              // cursor: "pointer",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              px: 2.5,

                              maxWidth: "83.3px",
                            }}
                            onClick={() => {
                              if (stepIndex <= activeStep) {
                                handleStep(stepIndex);
                              }
                            }}
                          >
                            <img
                              src={
                                stepIndex < activeStep
                                  ? `../../../assets/images/steps/completed-icon/${stepIndex}.svg`
                                  : stepIndex === activeStep
                                  ? `../../../assets/images/steps/active-icon/${stepIndex}.svg`
                                  : `../../../assets/images/steps/${stepIndex}.svg`
                              }
                              alt={stepObj.stepLabel}
                              loading="lazy"
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize:
                                  stepIndex === activeStep ? "16px" : "14px",
                                textAlign: "center",
                                fontWeight:
                                  stepIndex === activeStep ? 700 : 500,
                                color: IncognitoModeStatus
                                  ? theme.palette.primary.incognitoText
                                  : stepIndex < activeStep
                                  ? `#4A3D9E`
                                  : stepIndex === activeStep
                                  ? `#4A3D9E`
                                  : `#9E9EA6`,
                              }}
                            >
                              {stepObj.stepLabel === "References" &&
                              IncognitoModeStatus
                                ? "Payment "
                                : stepObj.stepLabel}
                              {/* {console.log(
                                stepObj.stepLabel === "References" &&
                                  IncognitoModeStatus,
                                "index === 7 && IncognitoModeStatus"
                              )} */}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                  <Box
                    sx={{ display: { xs: "block", md: "block", lg: "none" } }}
                  >
                    <TrueTips
                      message={steps[activeStep]["truetips"]}
                      vertical={"top"}
                      horizontal={"center"}
                      state={state}
                      setState={setState}
                      handleClose={handleCloseTrueTips}
                      autoHideDuration={10000}
                    />
                  </Box>
                  {/* stepper component */}
                  <Box>
                    {isMobile ? (
                      <Box>
                        <MobileStepper
                          sx={{
                            backgroundColor: IncognitoModeStatus
                              ? theme.palette.primary.incognitoBackground
                              : "inherit",
                          }}
                          variant="progress"
                          steps={steps.length}
                          position="static"
                          activeStep={activeStep}
                          nextButton={
                            <Button
                              size="small"
                              form={"validationForm"}
                              type="submit"
                              // onClick={handleNext}
                              disabled={activeStep === steps.length - 1}
                            >
                              {theme.direction === "rtl" ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>
                                    {activeStep + 1}/{steps.length}
                                  </Typography>
                                  <KeyboardArrowRight />
                                </Box>
                              )}
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              onClick={handleBack}
                              disabled={activeStep === 0}
                            >
                              {theme.direction === "rtl" ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowLeft />
                              )}
                            </Button>
                          }
                        />
                        {/* <Typography>{steps[activeStep]["stepLabel"]}</Typography> */}
                      </Box>
                    ) : (
                      // desktop stepper steps
                      <Stepper
                        // className="image-holder"
                        activeStep={activeStep}
                        type={"submit"}
                        form={"validationForm"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "flex-start",
                          flexWrap: { xs: "nowrap", md: "wrap" },
                          // pt: 3,
                          mt: { sm: 0, md: 4, lg: 0, xl: 0 },
                        }}
                        // overflow="auto"
                      >
                        {steps?.map((stepObj, stepIndex) => {
                          const stepProps = {};

                          return (
                            <Box
                              sx={{
                                // cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                p: 2,
                                maxWidth: "83.3px",
                              }}
                            >
                              <Box>
                                <img
                                  onClick={() => {
                                    if (stepIndex <= activeStep) {
                                      handleStep(stepIndex);
                                    }
                                  }}
                                  loading="lazy"
                                  src={
                                    stepIndex < activeStep
                                      ? `../../../assets/images/steps/completed-icon/${stepIndex}.svg`
                                      : stepIndex === activeStep
                                      ? `../../../assets/images/steps/active-icon/${stepIndex}.svg`
                                      : `../../../assets/images/steps/${stepIndex}.svg`
                                  }
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    fontWeight: 600,
                                    color: IncognitoModeStatus
                                      ? theme.palette.primary.incognitoText
                                      : stepIndex < activeStep
                                      ? `#4A3D9E`
                                      : stepIndex === activeStep
                                      ? `#4A3D9E`
                                      : `#9E9EA6`,
                                  }}
                                  key={stepIndex}
                                  {...stepProps}
                                >
                                  {stepObj.stepLabel === "References" &&
                                  IncognitoModeStatus
                                    ? "Payment"
                                    : stepObj?.stepLabel}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Stepper>
                    )}
                  </Box>
                  {/* questions form component */}
                  <Box
                    className="stepper-form"
                    sx={{
                      maxHeight: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "65vh",
                        xl: "65vh",
                      },
                      minHeight: {
                        xs: "65.1vh",
                        sm: "76.5vh",
                        md: "80.38vh",
                        lg: "unset",
                      },
                      // backgroundColor: "#FFF",
                      boxSizing: "border-box",
                      overflowY: "scroll",
                      pt: { xs: "25px", sm: "10px" },
                      pl: { xs: "25px", sm: "40px" },
                      pr: { xs: "25px", sm: "40px" },
                      // pb: { xs: 2, md: 8, lg: 0 },
                    }}
                  >
                    {activeStep !== steps.length && (
                      <React.Fragment>
                        <FormProvider {...methods}>
                          <form
                            id="validationForm"
                            onSubmit={methods.handleSubmit(handleNext)}
                          >
                            {handleSteps(activeStep)}
                          </form>
                        </FormProvider>
                      </React.Fragment>
                    )}
                  </Box>

                  {/* back and proceed buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: {
                        xs: "space-between",
                        lg: "space-between",
                      },
                      // top: "83%",
                      position: {
                        xs: "sticky",
                        md: "relative",
                        lg: "sticky",
                      },
                      zIndex: 1,
                      top: { xs: "92.8%", md: "0%", lg: "92.9%" },
                    }}
                  >
                    {UserFromTechValue && (
                      <Box
                        sx={{
                          pb: { xs: "10px", sm: "15px" },
                          pl: { xs: "25px", sm: "40px" },
                          pr: { xs: "25px", sm: "40px" },
                        }}
                      >
                        <LinearProgress
                          sx={{
                            height: "10px",
                            borderRadius: "20px",
                          }}
                          color="success"
                          variant="determinate"
                          value={Math.round(techWorth * 100) / 5}
                        />
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                          {/* <Typography variant="h4">{techValue}</Typography> */}
                          {activeStep > 0 && (
                            <Typography
                              variant="h5"
                              sx={{ textAlign: "center", pt: 1 }}
                            >
                              {reCalculate ? (
                                <RecalculatingText />
                              ) : (
                                `Your Value-add is at : ${Math.round(
                                  techWorth * 100
                                )}%`
                              )}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: {
                          xs: "space-between",
                          lg: "space-between",
                        },
                        // top: "83%",
                        alignItems: "center",
                        top: { xs: "92.8%", md: "0%", lg: "92.9%" },
                        position: {
                          xs: "sticky",
                          md: "relative",
                          lg: "sticky",
                        },
                        mb: { xs: "1vh" },
                        px: {
                          xs: "25px",
                          sm: "20px",
                          md: "40px",
                          lg: "40px",
                        },
                        zIndex: 1,
                        // pt: 3,
                      }}
                    >
                      {isMobile ? (
                        <Button
                          // className="form-button"
                          sx={{
                            backgroundColor: "#fff",
                            color: "#060623",
                            pl: "12px",
                            pr: "20px",
                            pb: "8px",
                            pt: "8px",
                            // ml: { xs: "4vw" },
                          }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          variant="outlined"
                          startIcon={<ArrowBackIosNewIcon />}
                        >
                          Back
                        </Button>
                      ) : (
                        <Button
                          id="back-button"
                          className="form-button"
                          sx={{
                            backgroundColor: "#fff",
                            zIndex: 1,
                            color: "#060623",
                            // ml: { lg: "2.7vw" },
                            borderColor: "#060623",
                            "&:hover": {
                              backgroundColor: "#fff !important",
                            },
                          }}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          variant="outlined"
                          startIcon={<ArrowBackIosNewIcon />}
                        >
                          Step Back
                        </Button>
                      )}

                      {UserFromTechValue && (
                        <Box>
                          {/* <Typography variant="h4">{techValue}</Typography> */}
                          {activeStep > 0 && (
                            <Typography
                              variant="h4"
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                  md: "block",
                                },
                              }}
                            >
                              {reCalculate ? (
                                <RecalculatingText />
                              ) : (
                                `Your Value-add is at : ${Math.round(
                                  techWorth * 100
                                )}%`
                              )}
                            </Typography>
                          )}
                        </Box>
                      )}
                      {/* <Box sx={{ flex: "1 1 auto" }} /> */}

                      {activeStep === 7 &&
                      referralDiscountPrice >= 0 &&
                      results >= 0 ? (
                        <PaymentForm
                          amount={referralDiscountPrice}
                          userName={userName}
                          userEmail={userEmail}
                          formData={formData}
                          invoiceDetails={invoiceDetails}
                        />
                      ) : (
                        <Button
                          id="proceed-button"
                          className="form-button"
                          form={"validationForm"}
                          type="submit"
                          variant="contained"
                          sx={{
                            // ml: {
                            //   lg: "27vw",
                            // },
                            // mr: { xs: "4vw", md: "0vw" },
                            backgroundColor: "#0D9B21",
                            "&:hover": {
                              backgroundColor: "#0D9B21 !important",
                            },
                          }}
                          endIcon={<ArrowForwardIosIcon />}
                        >
                          {activeStep === steps.length - 1
                            ? "Calculate"
                            : "Proceed"}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            {/* Footer */}
            {/* <Footer /> */}
          </>
        )}
      </>
    </React.Fragment>
  );
}

export default StepperForm;
