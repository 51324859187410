import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import React, { useState } from "react";
import apis from "../../utils/apis";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/action/loadingRequest";
import { setUserResult } from "../../redux/action/result";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getResult } from "./stepper.helper";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { v4 as uuidv4 } from "uuid";
import { Modal, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const PaymentForm = ({
  amount,
  userName,
  userEmail,
  formData,
  invoiceDetails,
}) => {
  const Razorpay = useRazorpay();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resultId = useSelector(
    (state) => state?.ResultReducer.resultId || null
  );
  const Razor_Pay_key = useFeatureFlag("Razor_Pay_key");
  const New_Theme = useFeatureFlag("New_Theme");
  const [loadingButton, setLoadingButton] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [finalResult, setFinalResult] = useState({
    marketSalary: "",
    techValue: "",
    techworth: "",
  });

  const handlePayment = useCallback(async () => {
    let order = "";

    try {
      setLoadingButton(true);
      handleOpen();

      order = await apis
        .POST({
          endpoint: "/orders",
          payload: { amount: amount * 100, currency: "INR", receipt: uuidv4() },
          options: {
            type: "json",
          },
        })
        .then((object) => {
          handleClose();
          setLoadingButton(false);
          return object;
        });
    } catch (error) {
      setLoadingButton(false);
      console.log(error);
    }

    const options = {
      key: Razor_Pay_key,
      amount: amount * 100,
      currency: "INR",
      name: "TrueSelfy",
      description:
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "test"
          ? "Test Transaction"
          : "TrueSelfy Techworth Transaction",
      image: `https://techworth.trueselfy.com/assets/images/TrueSelfy-logo.svg`,
      order_id: order.data?.id,
      handler: (res) => {
        // console.log(res, "razorpay response");
        const razorpay_payment_id = res?.razorpay_payment_id;
        dispatch(setLoading(true));
        getResult(
          formData,
          setFinalResult,
          dispatch,
          setUserResult,
          setLoading,
          navigate,
          razorpay_payment_id,
          amount,
          invoiceDetails
        );
        // const paymentsDetails = apis.POST({
        //   endpoint: "/payment",
        //   payload: { amount: amount, currency: "INR" },
        //   options: {
        //     type: "json",
        //   },
        // });
        // const paymentDetails = axios
        //   .post(`payments/${razorpay_payment_id}/capture`)
        //   .then(function (response) {
        //     console.log(response, "response");
        //   })
        //   .catch(function (error) {
        //     console.log(error, "error");
        //   });
        // console.log(paymentDetails, "paymentDetails");
      },
      prefill: {
        name: userName,
        email: userEmail,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: New_Theme ? "#21175E" : "#26801E",
      },
    };

    const rzpay = new Razorpay(options);

    rzpay.open();
    rzpay.on("payment.failed", function (response) {
      response.error.amount = amount * 100;
      response.error.currency = "INR";
      response.error.resultId = resultId;
      // console.log("response.error", response.error);

      try {
        apis
          .POST({
            endpoint: "/save-Failedpayment",
            payload: response.error,
            options: {
              type: "json",
            },
          })
          .then((object) => {
            // console.log("object", object);
          });
      } catch (error) {
        console.log(error);
      }
    });
  }, [Razorpay, amount]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
          position="fixed"
        >
          <CircularProgress
            sx={{
              color: "#fff",
            }}
          />
        </Box>
      </Modal>
      <LoadingButton
        form={"validationForm"}
        // type="submit"
        variant="contained"
        id="payment-btn"
        className="form-button"
        sx={
          {
            // ml: {
            //   lg: "18vw",
            //   xl: "25vw",
            // },
            // mr: { xs: "4vw", md: "0vw" },
            // backgroundColor: "#0D9B21",
            // "&:hover": {
            //   backgroundColor: "#0D9B21 !important",
            // },
          }
        }
        endIcon={<ArrowForwardIosIcon />}
        onClick={handlePayment}
        loading={loadingButton}
      >
        Pay & Calculate
      </LoadingButton>
    </div>
  );
};

export default PaymentForm;
