import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  RadioGroup,
  Switch,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
// import Tooltip from "@mui/material/Tooltip";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import MultiSelectQuestionComponent from "./MultiSelectQuestionComponent";
// import NumberQuestionComponent from "./NumberQuestionComponent";
// import ConditonalRadioQuestionComponent from "./ConditonalRadioQuestionComponent";
// import SelectQuestionComponent from "./SelectQuestionComponent";
// import TextQuestionComponent from "./TextQuestionComponent";
// import Avatar from "@mui/material/Avatar";
// import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// import DynamiCertificateQuestion from "./DynamiCertificateQuestion";
import ConditionalQuestions from "./ConditionalQuestions";
import TooltipComponent from "../../common/Tooltip";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { getIconForLabel } from "../../common/ToggleSwitchIcons";

function ConditonalRadioQuestionComponent({ question, questionsObject }) {
  const {
    control,
    getValues,
    unregister,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [fieldIsConditional, setFieldIsConditional] = useState(false);
  const [callUseEffect, setCallUseEffect] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    question?.conditional?.map((conditionalField, i) => {
      questionsObject?.map((conditionalQuestion, i) => {
        if (
          conditionalQuestion.questionId === conditionalField.questionId &&
          getValues(question.questionName) == "Yes"
        ) {
          setFieldIsConditional(true);
        } else if (
          conditionalQuestion.questionId === conditionalField.questionId &&
          getValues(question.questionName) === "No"
        ) {
          let getDataFrom = getValues(`suggestedObject`);
          _.unset(getDataFrom, conditionalQuestion.questionName);
          setValue("suggestedObject", getDataFrom);
          unregister(conditionalQuestion.questionName);
        }
      });
    });
  }, [callUseEffect]);

  // Use getIconForLabel to set the icon dynamically in your styled component
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 80,
    height: 45,
    padding: 12,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: `${theme.palette.primary}`,
        transform: "translateX(32px)",
        "& .MuiSwitch-thumb:before": {
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${getIconForLabel(question)})`, // Use the utility function to get the correct icon
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.primary,
      width: 40,
      height: 40,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#26801E"
        )}" d="M19,13H5V11H19V13Z" /></svg>')`, // Set default icon if none is selected
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  const handleConditionalQuestion = () => {
    callUseEffect ? setCallUseEffect(false) : setCallUseEffect(true);
    setFieldIsConditional(false);
  };

  return (
    <>
      <Grid
        item
        xs={question?.isSuggested ? 12 : 12}
        sx={{
          mb: 2,
          ml: question?.isSuggested ? 2 : 0,
          backgroundColor: question?.isSuggested
            ? theme.palette.primary.nestedQuestionBackground
            : "",
          py: question?.isSuggested ? 2 : 0,
          px: question?.isSuggested ? 1.5 : 0,
          borderRadius: question?.isSuggested ? 2 : 0,
          border: question?.isSuggested ? "1px solid #fff" : "none",
          boxShadow: question?.isSuggested
            ? "0px 2px 4px rgba(0, 0, 0, 0.2)"
            : 0,
          cursor: question?.isSuggested ? "pointer" : "",
        }}
      >
        <Typography
          // variant="h6"
          // component="h1"
          display="flex"
          // justifyContent={"center"}
          alignItems="center"
          justifyContent="space-between"
          // sx={{ fontWeight: "bold", py: 1 }}
          className="stepper-question"
        >
          <Box>
            {question.label}
            {question.rules.required && (
              <>
                &nbsp;<p style={{ color: "red" }}>*</p>
              </>
            )}
          </Box>

          {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
            <>
              <TooltipComponent message={question?.question_info} />
            </>
          ) : (
            <></>
          )}
        </Typography>
        <Controller
          name={question.questionName}
          control={control}
          defaultValue={question.defaultValue}
          rules={question.rules}
          render={({ field }) => (
            <FormControl>
              <Grid
                component="label"
                container
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <Grid item xs={4} lg={4}>
                  <Typography>No</Typography>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    // alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <MaterialUISwitch
                          {...field}
                          onChange={(event, newValue) => {
                            field.onChange(newValue ? "Yes" : "No");

                            question?.conditional?.length !== 0 &&
                            newValue === "Yes"
                              ? setFieldIsConditional(newValue)
                              : handleConditionalQuestion();
                          }}
                          checked={field.value === "Yes" ? true : false}
                        />
                      }
                      // label="Yes"
                    />
                  </RadioGroup>
                </Grid>
                <Grid xs={4} lg={4} item>
                  <Typography sx={{ pl: 2 }}>Yes</Typography>
                </Grid>
              </Grid>
            </FormControl>
          )}
        />
        {errors[question.questionName]?.type === "required" && (
          // <p className={"errorMsg"}>{question.errorMsg}</p>
          <FormHelperText error className={"errorMsg"}>
            {question.errorMsg}
          </FormHelperText>
        )}
      </Grid>
      {fieldIsConditional && (
        <ConditionalQuestions
          question={question}
          questionsObject={questionsObject}
        />
      )}
    </>
  );
}
export default ConditonalRadioQuestionComponent;
