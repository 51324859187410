import { useEffect, useState } from "react";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "../../common/Tooltip";
import { useTheme } from "@mui/material/styles";

function NumberQuestionComponent({ question }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  question.rules.pattern = /^[0-9]+$/;
  const experience = getValues("experience") || 0;
  const [experienceText, setExperienceText] = useState("");
  const [experienceEmoji, setExperienceEmoji] = useState("");

  const getExperiences = (value) => {
    if (value) {
      const totalMonths = parseInt(value, 10);
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;
      let experienceString = "";
      let emoji = "";

      // Define experience text and emoji based on input
      if (years > 0) {
        experienceString += `${years} ${years > 1 ? "years" : "year"}`;
        emoji = years >= 5 ? "🌟" : years >= 2 ? "🔥" : "👍"; // Customize emoji based on years
      }
      if (months > 0) {
        experienceString += ` ${months} ${months > 1 ? "months" : "month"}`;
        if (years === 0) {
          emoji = months >= 6 ? "✨" : "😊"; // Use different emoji for months
        }
      }

      setExperienceText(experienceString.trim());
      setExperienceEmoji(emoji);
    } else {
      setExperienceText("");
      setExperienceEmoji("");
    }
  };
  useEffect(() => {
    if (question.questionName === "experience") {
      getExperiences(experience);
    }
  }, []);

  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        mb: 2,
        ml: question?.isSuggested ? 4 : 0,
        backgroundColor: question?.isSuggested
          ? theme.palette.primary.nestedQuestionBackground
          : "",
        py: question?.isSuggested ? 2 : 0,
        px: question?.isSuggested ? 1.5 : 0,
        border: question?.isSuggested ? "1px solid #fff" : "none",
        borderRadius: question?.isSuggested ? 2 : 0,
        boxShadow: question?.isSuggested ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : 0,
        cursor: question?.isSuggested ? "pointer" : "",
      }}
    >
      <Typography
        // variant="h6"
        // component="h1"
        display="flex"
        alignItems="center"
        // justifyContent="space-between"
        // sx={{ fontWeight: "bold", lineHeight: "24px", py: 1 }}
        className="stepper-question"
      >
        {question.label}
        {question.rules.required && (
          <>
            &nbsp;<p style={{ color: "red" }}>*</p>
          </>
        )}

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <Tooltip message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>
      <Controller
        control={control}
        rules={question.rules}
        name={question.questionName}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus={question?.autoFocus}
            style={{
              width: "100%",
            }}
            onChange={(e, newValue) => {
              setValue(question.questionName, e.target.value);
              question?.isSuggested &&
                setValue(
                  `suggestedObject[${question.questionName}]`,
                  e.target.value
                );
              if (question.questionName === "experience") {
                let value = e.target.value;
                if (value) {
                  const totalMonths = parseInt(value, 10);
                  const years = Math.floor(totalMonths / 12);
                  const months = totalMonths % 12;
                  let experienceString = "";
                  let emoji = "";

                  // Define experience text and emoji based on input
                  if (years > 0) {
                    experienceString += `${years} ${
                      years > 1 ? "years" : "year"
                    }`;
                    emoji = years >= 5 ? "🌟" : years >= 2 ? "🔥" : "👍"; // Customize emoji based on years
                  }
                  if (months > 0) {
                    experienceString += ` ${months} ${
                      months > 1 ? "months" : "month"
                    }`;
                    if (years === 0) {
                      emoji = months >= 6 ? "✨" : "😊"; // Use different emoji for months
                    }
                  }
                  if (totalMonths === 0) {
                    emoji = "🎓";
                    experienceString = "Fresher/Intern/Trainee";
                  }

                  setExperienceText(experienceString.trim());
                  setExperienceEmoji(emoji);
                } else {
                  setExperienceText("");
                  setExperienceEmoji("");
                }
              }
              // console.log(
              //   "getCerti",
              //   getValues(`suggestedObject[${question.questionName}]`)
              // );
              // console.log("e.target.value", e.target.value);
            }}
            type="number"
            // size="small"
            inputProps={{ inputMode: "numeric" }}
            variant="outlined"
          />
        )}
      />
      {/* Display experience in years, months, and corresponding emoji */}
      {question.questionName === "experience" && experienceText && (
        <FormHelperText
          style={{
            fontSize: experienceText.includes("years") ? "1.1rem" : "0.9rem",
            fontWeight: "bold",
            color: "#4caf50", // Green color for positive feedback
          }}
        >
          {experienceEmoji} {experienceText}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "min" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {errors[question.questionName].message}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "max" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {errors[question.questionName].message}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "minLength" && (
        // <p className={"errorMsg"}>{question.minLengthErrorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.minLengthErrorMsg}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "maxLength" && (
        // <p className={"errorMsg"}> {question.maxLengthErrorMsg} </p>
        <FormHelperText error className={"errorMsg"}>
          {" "}
          {question.maxLengthErrorMsg}{" "}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "pattern" && (
        // <p className={"errorMsg"}> {"Enter only number"} </p>
        <FormHelperText error className={"errorMsg"}>
          {"Enter only number"}
        </FormHelperText>
      )}
      {/* <ErrorMessage message={"First name is required"} name={`${questionName}`} as={"text"} /> */}
    </Grid>
  );
}
export default NumberQuestionComponent;
