//import { ErrorMessage } from "@hookform/error-message";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// import InfoOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import TooltipComonent from "../../common/Tooltip";
import CheckBoxAccordingQuestionType from "../../common/CheckBoxAccordingQuestionType";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";

function SelectRadioQuestion({ question }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");

  const formdata = getValues();
  // console.log("formdata", formdata);
  const [selectedans, setSelectedans] = useState(
    formdata[question?.questionName] || ""
  );
  const theme = useTheme();
  const CustomTheme = useTheme();
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  // const classes = useStyles();

  // question.rules.pattern = new RegExp(question.rules.pattern);
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      whileInView={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, y: -200 }}
      transition={{ duration: 0.5 }}
    >
      <Grid
        item
        xs={12}
        my={1}
        textAlign={{ xs: "center", lg: "left" }}
        sx={{
          // boxShadow:
          //   "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
          border: IncognitoModeStatus ? "1px solid #fff " : "1px solid #8DE49A",
          p: { xs: 0.8, md: 1 },
          borderRadius: 1,
        }}
      >
        <Typography
          // variant="h6"
          display="flex"
          alignItems="center"
          // flexDirection={"row"}
          justifyContent="space-between"
          // textAlign={{ xs: "left", md: "center", lg: "left" }}
          // sx={{ fontWeight: "500", lineHeight: "24px", py: 1 }}
          className="stepper-question"
        >
          <Box>
            {question?.label}
            {question.rules.required && (
              <>
                &nbsp; <span style={{ color: "red" }}>*</span>
              </>
            )}
          </Box>

          {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
            <TooltipComonent message={question?.question_info} />
          ) : (
            <></>
          )}
        </Typography>
        <Typography
          py={0.5}
          mb={{ xs: 2, md: 0, lg: 0 }}
          // variant="h6"
          textAlign={{ xs: "center", md: "center", lg: "center" }}
          sx={{
            color: `${
              IncognitoModeStatus
                ? CustomTheme.palette.primary.incognitoText
                : theme.palette.primary.evalute
            }`,
            height: "40px",
            fontSize: { xs: "14px", md: "18px" },
          }}
        >
          {selectedans}
          {errors[question.questionName]?.type === "required" && (
            <Typography
              // variant="h6"
              sx={{
                color: "#F2250E",
                borderRadius: "10px",
                fontSize: { xs: "12px", md: "17px" },
                p: "0px 10px",
                pb: { xs: 2, md: 0 },
              }}
              display="inline-block"
            >
              {question.errorMsg}
            </Typography>
          )}
        </Typography>

        <Box
          pt={{ xs: 2, md: 2, lg: 0.5 }}
          display={{ xs: "inline", md: "inline", lg: "flex", xl: "flex" }}
        >
          <Controller
            name={question.questionName}
            control={control}
            rules={question.rules}
            render={({ field }) => (
              <>
                {question.options?.map((value, i) => {
                  return (
                    <FormControlLabel
                      sx={{
                        textAlign: "center",
                        margin: { xs: 0, md: 0 },
                        marginRight: { xs: 1 },
                        flex: 1,
                        "& .MuiFormControlLabel-label": {
                          fontSize: `${isMobile ? "12px" : "16px"}`,
                        },
                      }}
                      key={i}
                      labelPlacement="bottom"
                      control={
                        <CheckBoxAccordingQuestionType
                          field={field}
                          value={value}
                          question={question}
                          index={i}
                          setSelectedans={setSelectedans}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      }
                      label={`${value.shortName}`}
                    />
                  );
                })}
              </>
            )}
          />
        </Box>
        {/* {errors[question.questionName]?.type === "pattern" && (
        <p className={"errorMsg"}>{question?.patternErrorMsg}</p>
      )} */}
      </Grid>
    </motion.div>
  );
}
export default SelectRadioQuestion;
