export const SET_QUESTION_OBJECT = "SET_QUESTION_OBJECT";

export const SET_SUB_SKILLS = "SET_SUB_SKILLS";

export const SET_STEPPER_OBJECT = "SET_STEPPER_OBJECT";

export const setStepperObject = (response) => {
  return {
    type: SET_STEPPER_OBJECT,
    payload: response,
  };
};

export const setQuestionJson = (response) => {
  return {
    type: SET_QUESTION_OBJECT,
    payload: response,
  };
};

export const setSubSkills = (response) => {
  return {
    type: SET_SUB_SKILLS,
    payload: response,
  };
};
