import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Grid, Box, Typography, Fade } from "@mui/material";
import animationData from "../animations/0.json";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const LoadingAnimation = ({ animation, messages, activeStep }) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [delayMessage, setDelayMessage] = useState(false);

  const theme = useTheme();

  // Define breakpoints for mobile and tablet
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // Adjust height and width based on screen size
  const animationSize = {
    height: isMobile ? 300 : isTablet ? 300 : 400,
    width: isMobile ? 300 : isTablet ? 400 : 600,
  };

  const DefaultMessages = [
    "Calculating your Techworth...",
    "Analyzing your skills and inputs...",
    "Matching your expertise with industry trends...",
    "Crunching the numbers for optimal results...",
    "Almost there, hang tight!",
  ];

  useEffect(() => {
    // Cycle through short messages every 4 seconds (0-20 seconds)
    const messageInterval = setInterval(() => {
      setCurrentMessage((prev) => prev + 1);
    }, 3000); // Change message every 4 seconds

    // Trigger the delay message after 20 seconds
    const delayTimer = setTimeout(() => {
      setDelayMessage(true);
      clearInterval(messageInterval); // Stop cycling messages
    }, 500000); // 20 seconds

    return () => {
      clearInterval(messageInterval);
      clearTimeout(delayTimer);
    };
  }, []);
  useEffect(() => {
    setCurrentMessage(0);
  }, [activeStep]);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animation,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation === undefined ? animationData : animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet", // Keeps the animation fully visible within its container
      scaleMode: "fit", // Ensures the animation scales to fit the container
      clearCanvas: true, // Clears the canvas before rendering to prevent overlaps
    },
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: 5,
          textAlign: "center",
        }}
      >
        <Lottie
          options={defaultOptions}
          height={animationSize.height}
          width={animationSize.width}
        />
        <Fade in timeout={800} key={currentMessage}>
          <Typography
            variant="h6"
            sx={{ mt: 2, fontWeight: 500, color: "#555" }}
          >
            {delayMessage
              ? "We're still calculating your Techworth. This may take a bit longer based on your inputs."
              : messages === undefined
              ? DefaultMessages[currentMessage]
              : messages[currentMessage]}
          </Typography>
        </Fade>
      </Box>
    </Grid>
  );
};

export default LoadingAnimation;
