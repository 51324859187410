import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Box,
  Chip,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../../styles.css";
import TooltipComponent from "../common/Tooltip";

function EvaluationMultiSelectQuestionComponent({ question, username }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // question.options = ["ajay", "aj", "dev"];
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();

  const getColorByYear = (year) => {
    const currentYear = new Date().getFullYear();
    const yearDiff = currentYear - year;
    if (yearDiff < 1) {
      return "yellow";
    } else if (yearDiff < 5) {
      return "#F76E65";
    } else {
      return "#A6D4A9";
    }
  };
  // Define your custom validation function

  return (
    <Grid item xs={12} sx={{ pl: 1, pb: 2 }}>
      <Box
        py={2}
        display="flex"
        alignItems="center"
        flexDirection={"row"}
        justifyContent={"center"}
      >
        <Typography
          variant="h6"
          display="flex"
          alignItems="center"
          flexDirection={"row"}
          textAlign={"center"}
          sx={{ fontWeight: "500", lineHeight: "24px" }}
        >
          {question?.label?.replace(
            /(their|his\/her)/g,
            `${username}'s` || "their"
          ) || question?.label}
          {question.rules.required && (
            <>
              &nbsp;<p style={{ color: "red" }}>*</p>
            </>
          )}
          {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
            <>
              <TooltipComponent message={question?.question_info} />
            </>
          ) : (
            <></>
          )}
        </Typography>
      </Box>
      <Controller
        name={question.questionName}
        control={control}
        defaultValue={[]}
        rules={{
          required: question.rules.required,
          validate: {
            ...(question.rules.minLength && {
              minSelection: (value) => {
                if (value?.length >= 0) {
                  return (
                    value.length >= question.rules.minLength ||
                    question.minLengthErrorMsg.replace(
                      "%s",
                      question.rules.minLength
                    )
                  );
                }
                return true; // Validation passes if value length is not greater than one
              },
            }),
            ...(question.rules.maxLength && {
              maxSelection: (value) => {
                return (
                  value?.length <= question.rules.maxLength ||
                  question.maxLengthErrorMsg.replace(
                    "%s",
                    question.rules.maxLength
                  )
                );
              },
            }),
          },
        }}
        render={({ field }) => (
          <Autocomplete
            multiple
            {...field}
            onChange={(event, newValue) => {
              // console.log("newValue", newValue);
              field.onChange(newValue);
            }}
            size="small"
            id="size-small-standard"
            options={question.options}
            disableCloseOnSelect
            getOptionLabel={(option) => option || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "8px",
                        backgroundColor: getColorByYear(option.year),
                      }}
                    ></span>
                  }
                  checkedIcon={
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "8px",
                        backgroundColor: getColorByYear(option.year),
                      }}
                    ></span>
                  }
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                autoFocus={question?.autoFocus}
                {...params}
                size="small"
                label={question.placeholder}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={
                    <>
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          display: "inline-block",
                          marginRight: "8px",
                          backgroundColor: getColorByYear(option.year),
                        }}
                      ></span>
                      {option}
                    </>
                  }
                  style={{
                    marginRight: 8,
                    backgroundColor: "#fff",
                  }}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        )}
      />
      {errors[question.questionName]?.type === "required" && (
        <p className="errorMsg">{question.errorMsg}</p>
      )}

      {errors[question.questionName]?.type === "minSelection" && (
        <p className="errorMsg">{errors[question.questionName].message}</p>
      )}

      {errors[question.questionName]?.type === "maxSelection" && (
        <p className="errorMsg">{errors[question.questionName].message}</p>
      )}
    </Grid>
  );
}
export default EvaluationMultiSelectQuestionComponent;
