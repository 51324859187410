import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonComponent({ lines }) {
  // Ensure lines is a valid number, default to 1 if undefined
  const skeletonLines = Array.from({ length: lines || 1 });
  return (
    <Box>
      {skeletonLines.map((_, index) => (
        <Skeleton
          key={index}
          animation="wave"
          height={20}
          width="100%" // Customize width as needed
          //   style={{ marginBottom: 8 }} // Add spacing between skeletons
        />
      ))}
    </Box>
  );
}
