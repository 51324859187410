import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Box,
  FormHelperText,
  Chip,
  Avatar,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@mui/material/Typography";
import "../../../styles.css";
import TooltipComponent from "../../common/Tooltip";
import { useSelector } from "react-redux";
import SkeletonComponent from "../../common/Skeleton";
import { useTheme } from "@mui/material/styles";
function MultiSelectQuestionComponent({ question }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const subSkillOptions = useSelector(
    (state) => state.QuestionJsonReducer.subSkills || []
  );

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  // State to manage selected values
  const [selectedOptions, setSelectedOptions] = useState([]);

  const getIconBySkillName = (skillName) => {
    const svgIconPath = `../../../assets/images/icons/${skillName}.svg`;
    const pngIconPath = `../../../assets/images/icons/${skillName}.png`;
    const darkIconPath = `../../../assets/images/dark-icons/${skillName}.svg`;

    // Check if the SVG icon exists
    const svgExists = imageExists(svgIconPath);
    if (svgExists) {
      return svgIconPath;
    }
    // Check if the PNG icon exists
    const pngExists = imageExists(pngIconPath);
    if (pngExists) {
      return pngIconPath;
    }
    // Check if the dark SVG icon exists
    const darkExists = imageExists(darkIconPath);
    if (darkExists) {
      return darkIconPath;
    }
    // Return null if neither SVG nor PNG exists
    return null;
  };

  // Helper function to check if an image exists
  const imageExists = (url) => {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalHeight !== 0;
  };

  // Function to determine background color based on easeOfLearning
  const getChipBackgroundColor = (easeOfLearning) => {
    if (easeOfLearning >= 70) {
      return "#A8E6A3"; // Light Green for Easy
    } else if (easeOfLearning >= 50 && easeOfLearning < 70) {
      return "#FFD580"; // Light Orange for Medium
    } else {
      return "#FFA6A6"; // Light Red for Hard
    }
  };

  // Sorting function based on easeOfLearning and parentSkill
  const sortByParentSkillAndEaseOfLearning = (options) => {
    return [...options].sort((a, b) => {
      if (a.parentSkill === b.parentSkill) {
        return a.easeOfLearning - b.easeOfLearning;
      }
      return a.parentSkill.localeCompare(b.parentSkill);
    });
  };

  // Watch for changes in the selected options
  const currentValues = watch(question.questionName);

  useEffect(() => {
    if (question.questionName === "subSkill") {
      const options = subSkillOptions;

      if (currentValues) {
        const filteredValues = currentValues.filter((value) =>
          options.some((option) => option._id === value._id)
        );
        setSelectedOptions(sortByParentSkillAndEaseOfLearning(filteredValues));
      } else {
        setSelectedOptions([]);
      }
    } else {
      setSelectedOptions(currentValues || []);
    }
  }, [subSkillOptions, currentValues, question.questionName]);

  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      sx={{
        mb: 2,
        ml: question?.isSuggested ? 2 : 0,
        backgroundColor: question?.isSuggested
          ? theme.palette.primary.nestedQuestionBackground
          : "",
        py: question?.isSuggested ? 2 : 0,
        px: question?.isSuggested ? 1.5 : 0,
        borderRadius: question?.isSuggested ? 2 : 0,
        border: question?.isSuggested ? "1px solid #fff" : "none",
        boxShadow: question?.isSuggested ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : 0,
        cursor: question?.isSuggested ? "pointer" : "",
      }}
    >
      <Typography
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && <span style={{ color: "red" }}>*</span>}
        </Box>
        {question.question_info?.trim() && (
          <TooltipComponent message={question.question_info} />
        )}
      </Typography>
      {/* Legend for ease of learning */}
      {question.questionName === "subSkill" && (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mb: 2,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Box>
            <Chip
              label="Easy"
              style={{
                backgroundColor: "#A8E6A3", // Light Green for Easy
                color: "#000",
                marginRight: 8,
              }}
            />
            <Chip
              label="Medium"
              style={{
                backgroundColor: "#FFD580", // Light Orange for Medium
                color: "#000",
                marginRight: 8,
              }}
            />
            <Chip
              label="Hard"
              style={{
                backgroundColor: "#FFA6A6", // Light Red for Hard
                color: "#000",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                mr: { md: 1 },
                ml: { xs: 1, md: 2 },
                textAlign: { xs: "center", md: "inherit" },
                pt: { xs: 1, md: 0 },
              }}
            >
              (Ease of learning)
            </Typography>
          </Box>
        </Box>
      )}
      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            multiple
            {...field}
            value={selectedOptions}
            onChange={(event, newValue) => {
              setSelectedOptions(sortByParentSkillAndEaseOfLearning(newValue));
              field.onChange(newValue);
            }}
            options={
              question.questionName === "subSkill"
                ? subSkillOptions.filter(
                    (option) =>
                      !selectedOptions.some(
                        (selected) => selected._id === option._id
                      )
                  )
                : question.options
            }
            disableCloseOnSelect
            getOptionLabel={(option) =>
              question.questionName === "subSkill"
                ? option.subSkill
                : option || ""
            }
            renderOption={(props, option, { selected }) => {
              const iconUrl =
                question.questionName === "subSkill"
                  ? getIconBySkillName(option.parentSkill) ??
                    option.synonyms
                      ?.map(getIconBySkillName)
                      .find((url) => url) ??
                    getIconBySkillName(option?.parentSkill)
                  : null;

              return (
                <li
                  {...props}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {question.questionName === "subSkill" ? (
                    iconUrl ? (
                      <Avatar
                        src={iconUrl}
                        alt="Logo"
                        sx={{
                          width: 24,
                          height: 24,
                          marginRight: 1,
                          backgroundColor: "#fff",
                        }}
                      />
                    ) : (
                      <Avatar
                        alt="Icon"
                        sx={{
                          width: 25,
                          height: 25,
                          fontSize: 13,
                          marginRight: 1,

                          // backgroundColor: "#fff",
                        }}
                      >
                        {(() => {
                          if (!option.parentSkill) return "";

                          const words = option.parentSkill
                            .split(" ")
                            .filter((word) => word.length > 0);
                          let abbreviation = "";

                          const getFirstValidChar = (word) => {
                            for (let i = 0; i < word.length; i++) {
                              const char = word.charAt(i).toUpperCase();
                              if (/^[a-zA-Z0-9]$/.test(char)) {
                                return char;
                              }
                            }
                            return "";
                          };

                          if (words.length === 1) {
                            // Single word: Get the first valid character of the word
                            abbreviation = getFirstValidChar(words[0]);
                          } else if (words.length === 2) {
                            // Two words: Combine the first valid character of each word
                            abbreviation = words
                              .map((word) => getFirstValidChar(word))
                              .join("");
                          } else if (words.length === 3) {
                            // Three words: Combine the first valid character of each word
                            abbreviation = words
                              .map((word) => getFirstValidChar(word))
                              .join("");
                          } else if (words.length > 3) {
                            // More than three words: Only use the first valid character of the first word
                            abbreviation = getFirstValidChar(words[0]);
                          }

                          // Handle cases where abbreviation might still be empty (due to no valid characters)
                          if (!abbreviation) {
                            abbreviation = words
                              .map((word) => getFirstValidChar(word))
                              .join("");
                          }

                          return abbreviation;
                        })()}
                      </Avatar>
                    )
                  ) : (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 1 }}
                      checked={selected}
                    />
                  )}
                  {question.questionName === "subSkill"
                    ? option.subSkill
                    : option}
                </li>
              );
            }}
            // Render tags only for "subSkill"
            renderTags={
              question.questionName === "subSkill"
                ? (value, getTagProps) =>
                    value.map((option, index) => {
                      const iconUrl =
                        getIconBySkillName(option.parentSkill) ??
                        option.synonyms
                          ?.map(getIconBySkillName)
                          .find((url) => url) ??
                        getIconBySkillName(option?.parentSkill);

                      const isFixedSize = value.length <= 10;

                      // Helper to generate abbreviation
                      const getAbbreviation = (name) => {
                        const words = name
                          ?.split(" ")
                          .filter((word) => word.length > 0);
                        if (!words?.length) return "";

                        return words
                          .map(
                            (word) =>
                              word
                                .split("")
                                .find((char) => /^[a-zA-Z0-9]$/.test(char))
                                ?.toUpperCase() || ""
                          )
                          .slice(0, 3)
                          .join("");
                      };

                      return (
                        <Chip
                          key={option._id}
                          label={option.subSkill || ""}
                          style={{
                            backgroundColor:
                              getChipBackgroundColor(option.easeOfLearning) ||
                              "#f1f1f1",
                            color: "#000",
                          }}
                          {...getTagProps({ index })}
                          avatar={
                            iconUrl ? (
                              <Avatar
                                src={iconUrl}
                                alt="Icon"
                                sx={{
                                  width: isFixedSize ? 35 : 25,
                                  height: isFixedSize ? 35 : 25,

                                  // width: "auto",
                                  // height: isFixedSize
                                  //   ? "35px !important"
                                  //   : "auto", // Fixed height for 10 or fewer chips, auto otherwise
                                  fontSize: isFixedSize
                                    ? "15px !important"
                                    : "13px", // Fixed font size for 10 or fewer chips, default otherwise
                                  backgroundColor: "#fff",
                                  p: 0.4,
                                }}
                              />
                            ) : (
                              <Avatar
                                alt="Icon"
                                sx={{
                                  width: isFixedSize ? 35 : 25,
                                  height: isFixedSize ? 35 : 25,
                                  fontSize: isFixedSize ? 13 : 10,
                                  backgroundColor: "#fff",
                                }}
                              >
                                {(() => {
                                  if (!option.parentSkill) return "";

                                  const words = option.parentSkill
                                    .split(" ")
                                    .filter((word) => word.length > 0);
                                  let abbreviation = "";

                                  const getFirstValidChar = (word) => {
                                    for (let i = 0; i < word.length; i++) {
                                      const char = word.charAt(i).toUpperCase();
                                      if (/^[a-zA-Z0-9]$/.test(char)) {
                                        return char;
                                      }
                                    }
                                    return "";
                                  };

                                  if (words.length === 1) {
                                    // Single word: Get the first valid character of the word
                                    abbreviation = getFirstValidChar(words[0]);
                                  } else if (words.length === 2) {
                                    // Two words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length === 3) {
                                    // Three words: Combine the first valid character of each word
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  } else if (words.length > 3) {
                                    // More than three words: Only use the first valid character of the first word
                                    abbreviation = getFirstValidChar(words[0]);
                                  }

                                  // Handle cases where abbreviation might still be empty (due to no valid characters)
                                  if (!abbreviation) {
                                    abbreviation = words
                                      .map((word) => getFirstValidChar(word))
                                      .join("");
                                  }

                                  return abbreviation;
                                })()}
                              </Avatar>
                            )
                          }
                        />
                      );
                    })
                : undefined
            }
            renderInput={(params) => {
              const [showSkeleton, setShowSkeleton] = React.useState(true);

              React.useEffect(() => {
                if (selectedOptions && selectedOptions.length > 0) {
                  // Hide skeleton immediately if options are available
                  setShowSkeleton(false);
                } else {
                  // Show skeleton for 4 seconds, then switch to TextField
                  const timer = setTimeout(() => {
                    setShowSkeleton(false);
                  }, 12000);

                  return () => clearTimeout(timer); // Cleanup the timer on unmount
                }
              }, [selectedOptions]);

              // Render Skeleton or TextField based on the state
              if (showSkeleton && question.questionName === "subSkill") {
                return <SkeletonComponent lines={3} />;
              }

              return (
                <TextField
                  autoFocus={question?.autoFocus}
                  {...params}
                  label={question.placeholder}
                />
              );
            }}
          />
        )}
      />
      {errors[question.questionName]?.type === "required" && (
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
    </Grid>
  );
}
export default MultiSelectQuestionComponent;
